(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name postponedMatches.controller:PostponedMatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.postponements.postponedMatches')
    .controller('PostponedMatchesCtrl', PostponedMatchesCtrl);

  function PostponedMatchesCtrl($q, $state, $filter, $mdToast, $mdDialog, AclService, Matches, Entitats, LastPostponement, townHalls, currentSeason) {
    var vm = this;
    vm.ctrlName = 'PostponedMatchesCtrl';

    vm.isFiltered = false;
    vm.showList = false;
    vm.matches = [];
    vm.initDate = moment(currentSeason.iniDate).toDate();
    vm.endDate = moment(currentSeason.endDate).toDate();
    vm.townhalls = _.cloneDeep(townHalls);
    vm.queryOrder = 'date';
    vm.sort = 'date';
    vm.currentPage = 1;

    vm.matchesPerPage = 2550;
    vm.paginationLabel = {
      page: $filter('translate')('PAGE'),
      rowsPerPage: '',
      of: $filter('translate')('OF')
    };

    if (vm.townhalls.length === 1) {
      vm.selectedTownHall = vm.townhalls[0];
      applyFilters();
    }

    vm.reorder = reorder;
    vm.applyFilters = applyFilters;
    vm.showEditButton = showEditButton;
    vm.showAcceptAndRejectButton = showAcceptAndRejectButton;
    vm.showCreateButton = showCreateButton;
    vm.showResolveSwitch = showResolveSwitch;
    vm.editPostponement = editPostponement;
    vm.acceptPostponement = acceptPostponement;
    vm.rejectPostponement = rejectPostponement;
    vm.changeResolutionPostponement = changeResolutionPostponement;
    vm.showExpiredPostponement = showExpiredPostponement;

    function reorder(order) {
      vm.sort = order;
      query(vm.selectedTownHall.id, vm.initDate, vm.endDate, vm.currentPage, vm.sort);
    }

    function reload() {
      $state.reload();
    }

    function showExpiredPostponement(match) {
      return !AclService.isAdmin() && isExpired(match);
    }

    function showResolveSwitch(match) {
      return AclService.isAdmin() && isExpired(match) && match.lastPostponement.state !== 'RESOLVED_BY_COMMITTEE';
    }

    function acceptPostponement(match) {
      patchNewState(match, 'ACCEPTED', 'POSTPONEMENTS.SUCCESSFUL_ACCEPTED', reload);
    }

    function rejectPostponement(match) {
      patchNewState(match, 'REJECTED', 'POSTPONEMENTS.SUCCESSFUL_REJECTED', reload)
    }

    function changeResolutionPostponement(match) {
      var confirm = $mdDialog.confirm({
          onComplete: function afterShowAnimation() {
            // TODO: This is DOM manipulation throw angular, it shouldn't be like this
            var $dialog = angular.element(document.querySelector('md-dialog'));
            var $actionsSection = $dialog.find('md-dialog-actions');
            var $cancelButton = $actionsSection.children()[0];
            var $confirmButton = $actionsSection.children()[1];
            angular.element($confirmButton).removeClass('md-focused');
            angular.element($cancelButton).addClass('md-focused');
            $cancelButton.focus();

            angular.element($confirmButton).addClass('md-warn md-raised');
          }
        }
      )
        .title($filter('translate')('ALERT'))
        .textContent($filter('translate')('POSTPONEMENTS.CONFIRM_RESOLVE'))
        .ok($filter('translate')('CONTINUE'))
        .cancel($filter('translate')('CANCEL'));

      $mdDialog.show(confirm).then(function () {
        patchNewState(match, 'RESOLVED_BY_COMMITTEE', 'POSTPONEMENTS.SUCCESSFUL_CHANGED_RESOLUTION',
          function (match) {
            match.lastPostponement.state = 'RESOLVED_BY_COMMITTEE';
          }, function (match) {
            match.lastPostponement.resolved = false;
          });
      }, function (match) {
        match.lastPostponement.resolved = false;
      });
    }

    function patchNewState(match, newState, successMsg, functionOnSuccess, functionOnError) {
      var postponement = {
        state: newState
      };
      LastPostponement.update({id: match.id}, postponement, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(successMsg))
            .position('bottom left')
            .hideDelay(3000)
        );
        functionOnSuccess(match);
      }, function (error) {
        var message;
        if (error.status === 400) {
          message = 'POSTPONEMENTS.' + error.data.message;
        } else {
          message = 'SERVER_ERROR';
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
        if (angular.isDefined(functionOnError)) {
          functionOnError(match);
        }
      });
    }

    function showEditButton(match) {
      if (!AclService.can('POSTPONEMENT', 'U')) return false;
      if (isExpired(match)) return false;
      if (match.lastPostponement.state !== "PENDING") return false;
      if (teamIsMine(match.localTeam)) return true;
      return !hasValue(match.lastPostponement.time) && !hasValue(match.lastPostponement.facilityId);
    }

    function showAcceptAndRejectButton(match) {
      if (!AclService.can('POSTPONEMENT', 'U')) return false;
      if (match.lastPostponement.state !== "PENDING" || isExpired(match)) return false;
      return teamIsMine(match.visitorTeam) && hasValue(match.lastPostponement.date) && hasValue(match.lastPostponement.time) && hasValue(match.lastPostponement.facilityId);

    }

    function showCreateButton(match) {
      if (!AclService.can('POSTPONEMENT', 'C')) return false;
      if (isExpired(match)) return false;
      return match.lastPostponement.state === 'ACCEPTED' || match.lastPostponement.state === 'REJECTED';
    }

    function isExpired(match) {
      return moment(moment.now()).isAfter(match.lastPostponement.managementDeadline);
    }

    function editPostponement(match) {
      if (!AclService.can('POSTPONEMENT', 'U')) return false;
      $state.go('home.postponements.edit', {matchId: match.id});
    }

    function applyFilters() {
      vm.isFiltered = true;
      query(vm.selectedTownHall.id, vm.initDate, vm.endDate, vm.currentPage, vm.sort);
    }


    function queryMyEntities() {
      Entitats.query(function (data) {
        vm.myEntities = data;
        if (vm.matches.length !== 0) {
          vm.showList = true;
        }
      })
    }

    function query(townHallId, initDate, endDate, page, sort) {
      var deferredMatches = $q.defer();
      vm.promiseMatches = deferredMatches.promise;
      var parameters = {
        townHall: townHallId,
        initDate: moment(initDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        page: page,
        size: vm.matchesPerPage,
        postponement: true
      };
      //Optional parameters
      if (!_.isUndefined(sort)) {
        parameters.sort = sort;
      }
      Matches.get(parameters, function (data) {
        _.remove(vm.matches);
        angular.forEach(data.matches, function (match) {
          vm.matches.push(parseMatch(data, match));
        });
        queryMyEntities();
        vm.maxMatches = data.page.count;
        deferredMatches.resolve();
      }, function () {
        deferredMatches.resolve();
      });
    }

    function teamIsMine(team) {
      return !_.isUndefined(findItemById(vm.myEntities, team.entityId));
    }

    function parseMatch(data, match) {
      var facility = findItemById(data.facilities, match.facilityId);
      var localTeam = findItemById(data.teams, match.localTeamId);
      var visitorTeam = findItemById(data.teams, match.visitorTeamId);
      return {
        id: match.id,
        canPostpone: !_.isUndefined(localTeam) && !_.isUndefined(visitorTeam),
        localTeam: !_.isUndefined(localTeam) ? localTeam : {name: $filter('translate')('CALENDAR.REST')},
        visitorTeam: !_.isUndefined(visitorTeam) ? visitorTeam : {name: $filter('translate')('CALENDAR.REST')},
        date: moment(match.date).format('L'),
        time: match.time === null ? undefined : match.time,
        facility: !_.isUndefined(facility) ? facility : undefined,
        championship: findItemById(data.championships, match.championshipId).name,
        lastPostponement: parsePostponement(match)
      };
    }

    function parsePostponement(match) {
      return {
        id: match.lastPostponement.id,
        state: match.lastPostponement.state,
        date: moment(match.lastPostponement.date).format('L'),
        time: match.lastPostponement.time === null ? undefined : match.lastPostponement.time,
        facilityId: !_.isUndefined(match.lastPostponement.facilityId) ? match.lastPostponement.facilityId : undefined,
        details: match.lastPostponement.details,
        requesterId: match.lastPostponement.requesterId,
        receiverId: match.lastPostponement.receiverId,
        managementDeadline: match.lastPostponement.managementDeadline,
        resolved: match.lastPostponement.state === "RESOLVED_BY_COMMITTEE"
      }
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

    function hasValue(field) {
      return !_.isNull(field) && !_.isUndefined(field);
    }

  }
}());
